import React, { useEffect, useState } from "react";
import { node } from "prop-types";

const I18NContext = React.createContext({});
const { Provider, Consumer } = I18NContext;
const I18NConsumer = Consumer;

const I18NProvider = ({children}) => {
    const [language, setLanguage] = useState();
    return (
        <Provider
            value={{
                setLanguage, language
            }}
        >
            {children}
        </Provider>
    )
};

I18NProvider.propTypes = {
    children: node.isRequired,
};

export { I18NConsumer, I18NContext, I18NProvider };
