import React, { useEffect, useState } from "react"

const LayoutContext = React.createContext({})
const { Provider, Consumer } = LayoutContext
const LayoutConsumer = Consumer

const LayoutProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [language, setLanguage] = useState("es")
  const [navbarTransparent, setNavbarTransparent] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const navBarTransparent = window.scrollY < 50
      setNavbarTransparent(navBarTransparent)
    })
  }, [])

  return (
    <Provider
      value={{
        loading,
        hideHeader,
        language,
        navbarTransparent,
      }}
    >
      {children}
    </Provider>
  )
}

export { LayoutContext, LayoutConsumer, LayoutProvider }
