/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import './src/styles/index.css';

import {ImageGalleryProvider} from "../omochi-components/src/components/ImageGallery/Context";
import {LayoutProvider} from "./src/components/Layout/context";
import {I18NProvider} from "../omochi-components/src/components/i18n/Context";


export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        require(`intersection-observer`);
    }
};

export const wrapPageElement = ({element, props }) => {
    return (
      <I18NProvider>
            <LayoutProvider>
                <ImageGalleryProvider>
              {element}
              </ImageGalleryProvider>
          </LayoutProvider>
      </I18NProvider>

    );
};
