import * as R from 'ramda';

export const GetParsedGalleryImages = (
    items, path
) => {
    let parsedItems = [];
    if (items && items.length) {
        items.map(
            item => {
                const itemByKey = R.path(path, item);
                console.log("itemByKey: ", itemByKey, item);
                parsedItems.push({
                    url: itemByKey
                })
            }
        );
        console.log(parsedItems);
        return parsedItems;
    }
};


export const GetGalleryGrid = (images) => {
    const mainImage = images[0];
    const desktopGrid = images.slice(1);
    return {
        mainImage,
        desktopGrid,
    };
};
